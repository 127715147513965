<template src="./productCategory.html"></template>

<script>
import categoryCard from '../categoryCard/categoryCard';
import header from '../header/header';

export default {
  name: 'productCategory',
  components: {
    categoryCard,
    recommendationHeader: header,
  },
  data() {
    return {
      isRecomendedMaskLoading: false,
      subCategories: [],
      Product: '',
      products: {},
      mainLoading: false,
      // getRecomendedMasks: ''
    };
  },
  created() {
    // this.getProduct()
  },
  mounted() {
    this.getSubCategories();
  },
  methods: {
    imageError(e) {
      e.target.src = '/static/img/icons/dummy.svg';
    },
    getSubCategories() {
      this.products = {};
      this.loading = {};
      this.subCategories = [];
      this.mainLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/product-category/sociolla`, {
          params: {
            filter: {
              parent_id: this.$route.params.id,
            },
          },
        })
        .then((response) => {
          this.mainLoading = false;
          if (response.status === 200) {
            this.subCategories = response.data.data;
            // const cat = response.data.data;
            // for (const i in cat) {
            //   this.getProduct(cat[i].id_category)
            // }
            this.mainLoading = false;
          } else {
            this.subCategories = [];
          }
        })
        .catch((error) => {
          // this.subCategories = []
          this.mainLoading = false;
        });
    },
    setSubCategory(item) {
      this.$store.commit('setSubCategory', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'productCategory';
.home-page {
  .main-section {
    background: #f8f8f8;
    padding: 50px 0px 50px;
    .user-title-main {
      letter-spacing: 2px;
    }
    .welcome-text {
      padding: 0px dim(178, vw) 38px;
    }
    .back-btn {
      margin: 0px 25px 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      a {
        background: #ffe4ed;
        padding: 12px 20px;
        font-family: 'lato-medium';
        font-size: 20px;
        color: #b32656;
        line-height: 27px;
        letter-spacing: 1.67px;
        border-radius: 30px;
        text-transform: uppercase;
        margin: 0 16px 8px 0;
      }
    }
    .promotion-product {
      padding: 0px 43px 30px;
      clear: both;
      ul {
        margin: 0px;
        padding: 0px;
        .category-card {
          box-shadow: 6px 8px 9px 0 rgba(0, 0, 0, 0.1);
          border-radius: 0px 0px 6px 6px;
          .col-md-6 {
            li.block-product {
              margin: 0 0 26px 0;
              width: 100%;
              .info {
                span.title {
                  min-height: 120px;
                }
              }
            }
          }
        }
      }
      a {
        .imgwrap {
          display: block;
          width: 100%;
          height: 263px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .beauty-text {
        font-family: 'lato-medium';
        font-size: 24px;
        color: #000000;
        text-align: center;
        opacity: 0.6;
        margin-top: 20px;
        span {
          font-family: 'lato-bold';
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 24px 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-4 {
    width: 33.33%;
    padding: 0px 10px;
  }
}
</style>
